<template>
  <div class="check-in-items" @click="goToDetails(itemMap)">
    <div class="check-in-top flex middle">
      <div class="check-in-top-l active flex middle center">{{getJobFairType(itemMap.fairType)}}</div>
      <div class="check-in-top-r flex_1 van-ellipsis">{{itemMap.jobFairName}}</div>
    </div>
    <div class="check-in-middle flex between">
      <div class="check-in-middle-l flex_1 van-ellipsis">
        <div class="check-in-time flex middle">
          <img class="check-in-time-img" src="http://hxzp-web-hxrc-user.oss-cn-hangzhou.aliyuncs.com/wechatApp_icon/hlj/enterprise/check_in_time.png" alt />
          <div class="check-in-time-text van-ellipsis">{{getFormattedDate(itemMap.eventStartTime,itemMap.eventEndTime)}}</div>
        </div>
        <div class="check-in-time flex middle">
          <img class="check-in-time-img" src="http://hxzp-web-hxrc-user.oss-cn-hangzhou.aliyuncs.com/wechatApp_icon/hlj/enterprise/navigation.png" alt />
          <div class="check-in-time-text van-ellipsis">{{itemMap.fairAddress||'未知'}}</div>
        </div>
      </div>
      <div v-if="itemMap.jobFairTimeStatus&&itemMap.jobFairStatus===0" :class="{'hot-active':itemMap.jobFairTimeStatus===2}" class="check-in-middle-r">{{getJobFairStatus(itemMap.jobFairStatus,itemMap.jobFairTimeStatus)}}</div>
    </div>
    <van-divider style="margin:0;" />
    <div class="check-in-bom flex middle">
      <div class="companty-num flex middle">
        <span class="companty-num-l">企业</span>
        <!-- declareEnterprises -->
        <span class="company-num-r">{{itemMap.joinEnterprises}}</span>
      </div>
      <div class="companty-num flex middle">
        <span class="companty-num-l">报名</span>
        <span class="company-num-r">{{itemMap.applyWorker}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      itemMap: this.items
    };
  },
  watch: {
    items: function ( val ) {
      this.itemMap = val;
    }
  },
  methods: {
    getJobFairType ( type ) {
      if ( type === 1 ) {
        return '社'
      } else if ( type === 2 ) {
        return '校'
      }
    },
    getJobFairStatus ( status, timeStatus ) {
      if ( status === 0 ) {
        if ( timeStatus === 1 ) {
          return this.$myJs.dictValue( 'JOB_FAIR_TIME_STATUS', timeStatus )
        } else if ( timeStatus === 2 ) {
          return this.$myJs.dictValue( 'JOB_FAIR_TIME_STATUS', timeStatus )
        } else {
          return this.$myJs.dictValue( 'JOB_FAIR_TIME_STATUS', timeStatus )
        }
      }
    },
    goToDetails ( itemMap ) {
      this.$router.push( {
        path: '/details',
        query: {
          id: itemMap.id,
          flag: 'home'
        }
      } );
    },
    getFormattedDate ( enterpriseApplyStartTime, enterpriseApplyEndTime ) {
      if ( !enterpriseApplyStartTime && !enterpriseApplyEndTime ) return
      let startDate = new Date( enterpriseApplyStartTime ?.replace( /-/g, "/" ) );
      let endDate = new Date( enterpriseApplyEndTime ?.replace( /-/g, "/" ) );
      const startHours = startDate.getHours() < 10 ? `0${ startDate.getHours() }` : startDate.getHours();
      const startMinutes = startDate.getMinutes() < 10 ? `0${ startDate.getMinutes() }` : startDate.getMinutes();
      const endHours = endDate.getHours() < 10 ? `0${ endDate.getHours() }` : endDate.getHours();
      const endMinutes = endDate.getMinutes() < 10 ? `0${ endDate.getMinutes() }` : endDate.getMinutes();
      if ( startDate.toDateString() === endDate.toDateString() ) {
        return `${ startDate.getMonth() + 1 }月${ startDate.getDate() }日 ${ startHours }:${ startMinutes }-${ endHours }:${ endMinutes }`;
      } else {
        return `${ startDate.getMonth() + 1 }月${ startDate.getDate() }日 ${ startHours }:${ startMinutes }-${ endDate.getMonth() + 1 }月${ endDate.getDate() }日 ${ endHours }:${ endMinutes }`;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.check-in-items {
  background-color: #fff;
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 8px;
  .check-in-top {
    .check-in-top-l {
      color: #fff;
      font-size: 12px;
      width: 16px;
      height: 16px;
      background: #4097ff;
      border-radius: 4px 4px 4px 4px;
      &.active {
        background: #43d39d;
      }
    }
    .check-in-top-r {
      margin-left: 5px;
      font-size: 15px;
      font-weight: 500;
      color: #000000;
    }
  }
  .check-in-middle {
    margin-top: 12px;
    margin-bottom: 14px;
    .check-in-middle-l {
      .check-in-time {
        margin-bottom: 6px;
        .check-in-time-img {
          width: 14px;
          height: 14px;
          object-fit: cover;
        }
        .check-in-time-text {
          margin-left: 5px;
          font-size: 12px;
          font-weight: 400;
          color: #595959;
        }
      }
      .check-in-address {
      }
    }
    .check-in-middle-r {
      text-align: center;
      height: 22px;
      line-height: 22px;
      padding: 0 8px;
      background: linear-gradient(131deg, #2480f0 23%, #bee9ff 97%);
      border-radius: 9px 9px 9px 1px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      &.hot-active {
        background: linear-gradient(90deg, #ffb84d 0%, #e84a67 100%);
      }
    }
  }
  .check-in-line {
  }
  .check-in-bom {
    margin-top: 6px;
    .companty-num {
      margin-right: 20px;
      .companty-num-l {
        font-size: 12px;
        font-weight: 400;
        color: #8c8c8c;
        margin-right: 3px;
      }
      .company-num-r {
        font-size: 16px;
        font-weight: bold;
        color: #4097ff;
      }
    }
  }
}
</style>