<template>
  <div class="job-layout home-container">
    <div class="job-header">
      <van-tabs @click="vanTabsChange" background="#fff" v-model="active" sticky title-active-color="#303133" title-inactive-color="#606266">
        <van-tab v-for="(item,index) in vanTabsList" :key="index" :title="item.title"></van-tab>
      </van-tabs>
    </div>
    <div class="home-ul">
      <van-pull-refresh v-model="refreshing" @refresh="onrefresh">
        <van-list v-model="loading" :finished="finished" :offset="10" :immediate-check="false" finish-text="没有更多了" @load="onLoad">
          <template v-if="jobCheckInList.length>0">
            <div @click="goToDetails(items.id)" class="home-li" v-for="(items,idx) in jobCheckInList" :key="idx">
              <JobCheckIn :items="items" />
            </div>
          </template>
          <van-empty class="van-empty-customize" v-else description="暂无数据" />
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import JobCheckIn from '../components/job-check-in.vue'
// import { mapActions } from "vuex";
export default {
  components: { JobCheckIn },
  name: 'Login',
  data () {
    return {
      active: 0,
      loading: false,
      finished: false, //无
      refreshing: false, // 下拉状态
      listQuery: {
        page: 1,
        limit: 5
      },
      vanTabsList: [
        {
          title: '今日招聘会',
          type: 1
        },
        {
          title: '校园招聘会',
          type: 2
        },
        {
          title: '社会招聘会',
          type: 1
        }
      ],
      fairType: '',
      isToDay: 1,
      jobCheckInList: []
    }
  },
  created () { },
  mounted () {
    this.getCheckInList()
    // this.$myJs.dictValue( 'YES_NO', 0 )
  },
  methods: {
    goToDetails ( id ) {
      // console.log( id )
    },

    setInit () {
      this.jobCheckInList = []
      this.loading = false
      this.finished = false
      this.refreshing = false
      this.listQuery.page = 1
      this.getCheckInList()
    },
    vanTabsChange ( e ) {
      switch ( e ) {
        case 0:
          this.fairType = ''
          this.isToDay = 1
          break;
        case 1:
          this.fairType = 2
          this.isToDay = 0
          break;
        case 2:
          this.fairType = 1
          this.isToDay = 0
          break;
      }
      this.setInit()
    },
    // ...mapActions("user", ["login"]),
    // 上拉加载
    onLoad () {
      setTimeout( () => {
        this.getCheckInList()
      }, 1000 )
    },

    // 获取列表
    getCheckInList () {
      let params = {
        current: this.listQuery.page,
        form: {
          fairType: this.fairType,
          isToDay: this.isToDay,
          jobFairStatus: 0
        },
        size: this.listQuery.limit
      }
      this.$api.home
        .homeList( params )
        .then( res => {
          // console.log( res )

          if ( this.listQuery.page === 1 ) {
            this.jobCheckInList = res.data.records
          } else {
            this.jobCheckInList = this.jobCheckInList.concat( res.data.records )
            this.loading = false
          }
          if ( res.data.records.length > 0 ) {
            this.listQuery.page++
            this.loading = false
          } else {
            this.finished = true
          }
        } )
        .catch( () => {
          this.loading = false
          this.refreshing = false
        } )
    },
    onrefresh () {
      setTimeout( () => {
        this.setInit()
      }, 1000 )
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-tab__text--ellipsis {
  font-size: 16px !important;
}
/deep/ .van-tabs__line {
  background-color: #3c9cff;
  bottom: 22px;
}
.home-container {
  background-color: #f5f5f5;
  .job-header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  .home-ul {
    padding: 10px;
    padding-top: 53px;
  }
}
</style>
